#lv-breadcrumb{
  .breadcrumb-item{
    a{
      text-decoration: none;
      color: #505050;
      font-size: 0.8rem;
    }
    .active{
      font-weight: 500;
      color: $dark;
    }
    &:before{
      font-size: 0.8rem;
      line-height: 1.4rem;
    }
  }
}