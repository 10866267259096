$height: 650px;
$h1: 60px;
$h2: 45px;
$h3: 32px;
#component-banner {
    min-height: $height;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    .banner-c{
        height: $height;
        .description-container{
            h1{
                font-size: $h1;
            }
            h2{
                font-size: $h2;
            }
            h3{
                font-size: $h3;
            }
        }
    }
}

@include media-breakpoint-up(sm) {
   
}

@include media-breakpoint-up(xl) {
    
}
