#footer-container{
    padding: 5rem 0;
    .bottom-line{
      height: .8rem;
      background-color: $secondary;
      border: 0;
      opacity: 1;
    }
    .top-line{
        height: .1rem;
        background-color: $secondary;
        border: 0;
        opacity: 1;
    }
    p, 
    a{
      font-size: $small-font-size;
    }
}
