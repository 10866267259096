// Body
$body-bg: #ffffff;

// Typography
$font-family-sans-serif: 'Montserrat', sans-serif;
// $font-size-base: 0.9rem;
$line-height-base: 1.6;

// Carousel
$carousel-control-opacity:  1;
// Colors
$primary: #0e0e0e;
$secondary: #d70f35;
$purple: #9561e2;
$pink: #f66d9b;
$red: #772436;
$yellow: #ffed4a;
$green: #7e8744;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$gray: #f7f7f7;
$light: #fafafa;
$dark: #222222;

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $green,
  "info":       $cyan,
  "warning":    $yellow,
  "danger":     $red,
  "light":      $light,
  "gray":       $gray,
  "dark":       $dark
);
// Allows for customizing button radius independently from global border radius
$btn-border-radius:           0;
$btn-border-radius-sm:        0;
$btn-border-radius-lg:        0;

$base_shadow: 0px 0px 11px 0px rgba(159, 159, 159, 0.58);