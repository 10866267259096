// Mobile first
#navbar-container {
    padding: 0;
    padding-bottom: 1rem;
    .top-menu{

        .top-line{
            height: .8rem;
            background-color: $secondary;
            border: 0;
            opacity: 1;
        }
        .bottom-line{
            height: .1rem;
            background-color: $secondary;
            border: 0;
            opacity: 1;
        }
        .langs{
            .nav{
                .nav-item{
                    .nav-link{
                        padding: 0 5px;
                    &.active{
                        font-weight: bold;
                    }
                    }
                }
            }
        }
        .icons{
            .nav{
                .nav-item{
                    .nav-link{
                        padding: 0 15px;
                    }
                    .dropdown-menu{
                        background: transparent;
                        border: 0;
                    }
                }
            }
        }
        
    }
    .navbar-brand {
        img {
            // width: 180px;
        }
    }
    .navbar-toggler{
        border-radius: 0;
    }
    #navbarList{
        justify-content: space-between;
        .navbar-nav{
            .nav-item{
                .nav-link{
                    &:hover,
                    &.nav-active{
                        color: $secondary;
                    }
                }
            }
        }
    }
    // .nav-item {
    //     font-size: 1.2rem;
    //     .nav-link{
    //         text-transform: lowercase;
    //         color: $green;
    //         padding-top: .2rem;
    //         padding-bottom: .2rem;
    //         &:hover,
    //         &.nav-active{
    //             color: $indigo;
    //         }
    //         &.nav-active{
    //             font-weight: normal;
    //         }
    //     }
    // }
}

@include media-breakpoint-up(md) {
    #navbar-container {
        #navbarList{
            justify-content: center;
            .navbar-nav{
                .nav-item{
                    margin-left: 2rem;
                    margin-right: 2rem;
                }
            }
        }
    }
}
