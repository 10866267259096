#home-page {
    .banner-clienti {
        .banner-container {
            .left {
                background-position: 10%;
                background-repeat: no-repeat;
                height: 100%;
            }
        }
    }
    .applicazioni{
        .application-description-container{
            .products{
                .product{
                    a{
                        display: flex;
                        flex-direction: column;
                    }
                }
            }
        }
    }
    .banner-hardox {
        .banner-container {
            background-color: $gray;
            padding: 0;
            .left-skew,
            .right-skew {
                padding: 1rem;
            }
            .left-skew {
                background-color: $gray;
                .content {
                    .bar-c {
                        position: relative;
                        .progressbar-text {
                            font-weight: bold;
                            font-size: 2rem !important;
                        }
                        #bar3{
                            .progressbar-text {
                                .text {
                                    font-weight: bold;
                                    font-size: 2.5rem !important;
                                    text-align: center;
                                    margin-top: 3rem;
                                    line-height: 0;
                                }
                                .sub-text {
                                    font-weight: bold;
                                    font-size: 1.6rem !important;
                                    text-align: center;
                                }
                            }
                        }

                    }
                }
            }
            .right-skew {
                background-color: $red;
                color: white;
            }
        }
    }
    .anteprima-news {
        .first{
            .card {
                .card-img-top{
                    height: 300px;
                }
            }
        }
    }
    .newsletter{
        .form-group,
        .form-check,
        #select-component-lang{
            .invalid-feedback{
                color: $primary;
            }
        }
        .form-check,
        .newsletter-footer{
            a{
                color: $light;
                font-weight: bold;
            }
        }
        .newsletter-footer{
            small{
                color: #ee9da4;
                a{
                    color: #ee9da4;
                }
            }
        }
        #select-component-lang{
            .form-label{
                margin-bottom: 0 !important;
            }
            .btn{
                border-radius: 5px;
            }
        }
    }
}
@include media-breakpoint-up(sm) {
    #home-page {
        .banner-hardox {
            .banner-container {
                width: 100%;
                height: 100%;
                display: flex;
                overflow: hidden;
                background-color: $gray;
                .left-skew,
                .right-skew {
                    width: calc(50% + 115px);
                    height: 100%;
                    transform: skewX(-15deg);
                }
                .left-unskew,
                .right-unskew {
                    transform: skewX(15deg);
                }

                .left-skew {
                    margin-left: -115px;
                    padding-left: 177px;
                    background-color: $gray;
                    .content {
                        padding-right: 7rem;
                    }
                }
                .right-skew {
                    margin-right: -115px;
                    padding-right: 115px;
                    background-color: $red;
                    // height: 460px;
                    .content {
                        padding: 4rem;
                        padding-left: 7rem;
                    }
                }
            }
        }
        .applicazioni{
            .application-description-container{
                .products{
                    .product{
                        a{
                            display: flex;
                            align-items: center;
                            flex-direction: row;
                        }
                    }
                }
            }
        }
        .anteprima-news {
            .first{
                .card {
                    height: 350px;
                    &.d-flex {
                        .card-img-top,
                        .card-body {
                            width: 50%;
                        }
                        .card-img-top{
                            height: 100%;
                        }
                    }
                }
            }
        }
    }
}
@include media-breakpoint-up(xxl) {
}
