// Variables
@import 'variables';

// Import bootstrap variables including default color palette and fonts
@import "../../../node_modules/bootstrap/scss/_functions.scss";
@import "../../../node_modules/bootstrap/scss/_variables.scss";

// Bootstrap
@import '~bootstrap/scss/bootstrap';
// Bootstrap Select picker
@import '~bootstrap-select/sass/bootstrap-select.scss';
// Leaflet
@import "~leaflet/dist/leaflet.css";

// layouts
@import 'layout/breadcrumb';
@import 'layout/navbar';
@import 'layout/footer';
// Components
@import 'components/banners';
@import 'components/news_c';

// Pages
@import 'pages/home';
@import 'pages/azienda';
@import 'pages/contatti';
@import 'pages/lavora-con-noi';
@import 'pages/news';
@import 'pages/prodotti';

@import 'main';
// @import 'plugins/tempusdominus.scss';
