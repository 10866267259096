#product-page {
    .buttons {
        .btn-group {
            .btn {
                background: gray;
                border-radius: 1rem;
                padding-top: 1rem;
                padding-bottom: 1rem;
                font-weight: bold;
                text-transform: uppercase;
                color: white;
                margin-left: 1rem;
                margin-right: 1rem;
                &.active{
                    background-color: $secondary;
                }
            }
        }
    }
    .prodotti-container {
        .card {
            border: none;
            background-color: transparent;
            margin-top: 2rem;
            .card-body {
                border-left: 1px solid $secondary;
                border-radius: 0;
            }
        }
        .load-more__btn-wrap{
            text-align: center;
            margin-top: 50px;
        }
    }
}

#product-detail-page {
    .product-image-container {
        margin-top: 170px;
        .carousel {
            .carousel-item{
                img{
                    width:100%;
                    display: inline;
                }
            }
            .carousel-control-prev,
            .carousel-control-next {
                filter: invert(1);
            }
        }
    }
    .product-description-container{
        .tags{
            .circle{
                width: 30px;
                height: 30px;
                -moz-border-radius: 70px; 
                -webkit-border-radius: 70px; 
                background-color: #76807d;
                border-radius: 70px;
                &.hardox-500{
                    background-color: #2f6c2b;
                }
                &.hardox-450{
                    background-color: #ab7d6e;

                }
            }
        }
        .description_tow{
            h3{
                // font-size: 1rem;
                font-weight: 700;
                color: $secondary;
            }
            ul {
                position: relative;
                list-style: none;
                margin-left: 0;
                padding-left: 1em;
                li:before {
                    content: "+";
                    position: absolute;
                    left: 0;
                    font-weight: 700;
                    color: $secondary;
                }
            }
        }
    }
    .gallery-container{
        border-left: 4px solid $secondary;
        .gallery_thumbs{
            .image-c{
                cursor: pointer;
                img{
                    max-width: 100%;
                    object-fit: cover;
                }
                &:hover{
                    border: 1px solid $dark !important;
                }
            }
        }
    }
}
@include media-breakpoint-down(xs) {
    #product-detail-page {
        .gallery-container{
            .gallery_thumbs{
            }
            
        }

    }
}
