#component-news {
    .news-item {
        padding-bottom: .5rem;
        margin-top: 10px;
        margin-bottom: 10px;
        .card {
            border-radius: 0;
            box-shadow: $base_shadow;
            -webkit-box-shadow: $base_shadow;
            -moz-box-shadow: $base_shadow;
            // Home slider card CSS
            &:not(.d-flex) {
                .card-img-top{
                    height: 300px;
                    object-fit: cover;
                }
                .card-body {
                    z-index: 1;
                }
            }
            // News page css
            &.d-flex {
                margin-top: 1rem;
                margin-bottom: 1rem;
                .card-img-top{
                    object-fit: cover;
                }
            }
            .card-img-top{
                border-top-right-radius: 0;
                border-top-left-radius: 0;
            }
            .card-body{
                height: 230px;
                .card-title{
                    line-height: 1;
                }
                a.btn{
                    position: absolute;
                    bottom: 10px;
                }
            }
        }
    }
}
@include media-breakpoint-up(sm) {
    #component-news {
        .news-item {
            padding-bottom: 1rem;
            .card {
                // Home slider card CSS
                &:not(.d-flex) {
                }
                // News page css
                &.d-flex {
                    height: 250px;
                    .card-img-top{
                        width: 350px;
                    }
                }
            }
        }
    }

}